import { cleanVersion } from './versions';

export type ProjectFlavor = 'development' | 'beta' | 'live';
export const allFlavors: ProjectFlavor[] = [
  'development', 'beta', 'live'
];

export const flavorToBranchName = (flavor: ProjectFlavor) => {
  switch (flavor) {
    case 'development':
      return 'development';
    case 'beta':
      return 'staging';
    case 'live':
      return 'live';
  }
};

export const flavorToBranchNameVersioned = (flavor: ProjectFlavor, version: string) => {
  const cleanedVersion = cleanVersion(version);
  switch (flavor) {
    case 'development':
      return 'development';
    case 'beta':
      return `staging/${cleanedVersion}`;
    case 'live':
      return `live/${cleanedVersion}`;
  }
};
