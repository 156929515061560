import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { projectIdToName } from '../../api/config';
import { useDataContext } from '../../api/data-context/data-context';
import { loadTagDetails } from '../../api/data-context/effects';
import { useLatestTagsDetail } from '../../api/data-context/select';
import { ProjectFlavor } from '../../util/flavor-to-branch-name';
import { VersionDetails } from './VersionDetails';

export interface ProjectDetailProps {
}

const ProjectDetailView = (props: ProjectDetailProps) => {
  let { projectId, flavor } = useParams();
  const name = projectIdToName(projectId);
  const details = useLatestTagsDetail(projectId, flavor as ProjectFlavor);
  const latestVersion = details.tag;
  return <React.Fragment>
    <div className='uk-align-center'>
      <div className='uk-text-large uk-text-center uk-text-primary'>{name}</div>
      <div className='uk-text-medium uk-text-center uk-text-uppercase'>
        {flavor}
      </div>
      <div className='uk-text-large uk-text-center uk-text-emphasis'>{latestVersion}</div>
    </div>
    <div>
      <VersionDetails latestVersion={latestVersion}></VersionDetails>
    </div>
  </React.Fragment>;
};

export const ProjectDetail = (props: ProjectDetailProps) => {
  let { projectId } = useParams();
  const { state, dispatch } = useDataContext();
  const isLoaded = Boolean(state.tagDatas[projectId]);
  useEffect(() => {
    loadTagDetails(dispatch, { projectId });
  }, [projectId]);
  return <React.Fragment>
    {
      isLoaded ? <ProjectDetailView {...props}></ProjectDetailView> : <div>Loading</div>
    }
  </React.Fragment>;
};
