import { ProjectFlavor } from '../../util/flavor-to-branch-name';
import { compareVersions } from '../../util/versions';
import { useDataContext } from './data-context';

export const useTagsDetail = (projectId: string) => {
  const { state } = useDataContext();
  return state?.tagDatas[projectId];
}

export const useLatestTagsDetail = (projectId: string, flavor: ProjectFlavor) => {
  const { state } = useDataContext();
  const projectData = state.latestTags[projectId];
  return projectData && projectData[flavor];
};

export const useTagDetailsBeforeTag = (projectId: string, flavor: ProjectFlavor, tag: string) => {
  const { state } = useDataContext();
  if (!tag) {
    return [];
  }
  const tagDatas = state.tagDatas[projectId];
  if (!tagDatas) {
    return [];
  }
  const datas = tagDatas.filter(data => {
    const comparsion = compareVersions(tag, data.tag);
    return comparsion !== -1;
  });
  return datas;
};

export const useLatestCommits = (projectId: string, flavor: ProjectFlavor) => {
  const { state } = useDataContext();
  const allTagDatas = state.latestCommits[projectId];
  const latestCommits = allTagDatas && allTagDatas[flavor];
  if (!latestCommits) {
    return null;
  }
  return latestCommits;
};
