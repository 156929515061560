import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLatestTagsDetail } from '../../api/data-context/select';
import { TagsDetail } from '../../api/rest-types';
import { ProjectFlavor } from '../../util/flavor-to-branch-name';
import { getCreatedAt } from '../../util/versions';
import './ProjectCard.scss';

export interface ProjectCardProps {
  projectId: string;
  flavor: ProjectFlavor;
}

const CardComponent = (props: ProjectCardProps & { latestTagData: TagsDetail }) => {
  const { projectId, latestTagData, flavor } = props;
  const latestVersion = latestTagData?.tag || '???';
  const createdAt = getCreatedAt(latestTagData);
  return <div className='wdh-project-card uk-card uk-card-default uk-card-small uk-width-1-1'>
    <div className='uk-card-header'>
      <div className='uk-grid-small uk-flex-middle' uk-grid="true">
        <div className='uk-width-expand'>
          <h3 className='uk-card-title uk-margin-remove-bottom uk-text-uppercase'>{flavor}</h3>
          <p className='uk-text-meta uk-margin-remove-top'><time>{latestVersion}</time></p>
          <h6 className='uk-text-meta uk-margin-remove-top'>
            {createdAt?.toDateString()}
          </h6>
        </div>
      </div>
    </div>
    <div className='wdh-project-card-footer uk-card-footer'>
      <div className='uk-button uk-button-text'>
        <RouterLink to={`${projectId}/${flavor}`}>
          Details
        </RouterLink>
      </div>
    </div>
  </div>
};

export const ProjectCard = (props: ProjectCardProps) => {
  const { projectId, flavor } = props;
  const latestTagData = useLatestTagsDetail(projectId, flavor);
  return <React.Fragment>
    <CardComponent {...props} latestTagData={latestTagData}></CardComponent>
  </React.Fragment>
};
