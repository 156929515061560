import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/uikit/dist/js/uikit';
import '../node_modules/uikit/dist/js/uikit-icons';
import { DataProvider } from './api/data-context/data-context';
import { ProjectDetail } from './components/detail/ProjectDetail';
import Dashboard from './components/home/Dashboard';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <DataProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="/" element={<Dashboard />} />
            <Route path=":projectId/:flavor" element={
              <ProjectDetail></ProjectDetail>
            } />
          </Route>
        </Routes>
      </BrowserRouter>
    </DataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
