import React, { useEffect } from 'react';
import { projectIdToName } from '../../api/config';
import { useDataContext } from '../../api/data-context/data-context';
import { loadTagDetails } from '../../api/data-context/effects';
import { ProjectCard } from './ProjectCard';

export interface ProjectViewProps {
  projectId: string;
}

const ProjectCardListView = (props: ProjectViewProps) => {
  const { projectId } = props;
  const name = projectIdToName(projectId);
  return <React.Fragment>
    <div className='uk-heading-small uk-margin-small-top uk-margin-small-bottom uk-text-center'>
      {name}
    </div>
    <div className='uk-margin-medium-bottom uk-flex-around' uk-grid="true">
      <div className='uk-width-1-4'>
        <ProjectCard flavor='development' projectId={projectId}></ProjectCard>
      </div>
      <div className='uk-width-1-4'>
        <ProjectCard flavor='beta' projectId={projectId}></ProjectCard>
      </div>
      <div className='uk-width-1-4'>
        <ProjectCard flavor='live' projectId={projectId}></ProjectCard>
      </div>
    </div>
  </React.Fragment>
};

export const ProjectCardList = (props: ProjectViewProps) => {
  const { projectId } = props;
  const { state, dispatch } = useDataContext();
  const isLoaded = Boolean(state.tagDatas[projectId]);
  useEffect(() => {
    if (isLoaded) {
      return;
    }
    loadTagDetails(dispatch, { projectId });
  }, [projectId]);
  return <React.Fragment>{
    isLoaded ? <ProjectCardListView {...props}></ProjectCardListView> : <div>Loading</div>
  }</React.Fragment>
};
