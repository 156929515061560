import { allFlavors, flavorToBranchName, flavorToBranchNameVersioned, ProjectFlavor } from '../../util/flavor-to-branch-name';
import { versionCutLastPart } from '../../util/versions';
import { WEN_PROJECT_ID } from '../config';
import { DataUtil } from '../services/data-service';
import { RestApi } from '../services/rest-api';
import { DataContextDispatch, LatestTagState, RequestLoadLatestCommits } from './types';

export const loadTagDetails = async (dispatch: DataContextDispatch, props: { projectId: string }) => {
  const { projectId } = props;
  dispatch({ type: 'request load tag details', projectId });
  try {
    const data = await RestApi.getTagDetails({ projectId, sourceProjectId: WEN_PROJECT_ID });
    const latestTagData: LatestTagState = allFlavors.reduce((acc, currentFlavor) => {
      return {
        ...acc,
        [currentFlavor]: DataUtil.getLatestDataForFlavor(data, (branchName: string) => branchName.startsWith(flavorToBranchName(currentFlavor)))
      };
    }, {});
    dispatch({ type: 'finish load tag details', projectId, data, latestTagData });
  } catch (error) {
    console.log(error);
  }
};

export const loadLatestCommits = async (dispatch: DataContextDispatch, props: Omit<RequestLoadLatestCommits, 'type'>) => {
  const { projectId, flavor, latestVersion } = props;
  dispatch({ type: 'request load latest commits', projectId, flavor, latestVersion });
  try {
    const to = flavorToBranchNameVersioned(flavor as ProjectFlavor, versionCutLastPart(latestVersion));
    const data = await RestApi.getCommitsBetween({
      projectId: projectId,
      from: latestVersion,
      to
    });
    dispatch({ type: 'finish load latest commits', projectId, data, flavor });
  } catch (error) {
    console.log(error);
  }
};
