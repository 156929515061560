import { WEN_PROJECT_ID } from '../api/config';
import { TagsDetail } from '../api/rest-types';
import { ProjectFlavor } from './flavor-to-branch-name';

const semverCompare = require('semver-compare');

export const cleanVersion = (version: string) => {
  return version.replace('v', '');
};

export const versionCutLastPart = (version: string) => {
  return version.replace(/\.\d*$/, '');
};

export const compareVersions = (
  version1: string, version2: string
) => {
  const v1 = cleanVersion(version1);
  const v2 = cleanVersion(version2);
  return semverCompare(v1, v2);
};

export const shouldFetchUnreleasedCommits = (
  projectId: string, flavor: ProjectFlavor
) => {
  if (projectId !== WEN_PROJECT_ID || flavor === 'live') {
    return false;
  }
  return true;
};

export const getCreatedAt = (tagData: TagsDetail) => {
  const latestTagCommit = tagData?.commits[0];
  let createdAt: Date;
  if (latestTagCommit) {
    createdAt = new Date(latestTagCommit.created_at as unknown as string);
  }
  return createdAt;
};
