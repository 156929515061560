import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDataContext } from '../../api/data-context/data-context';
import { loadLatestCommits } from '../../api/data-context/effects';
import { useLatestCommits, useLatestTagsDetail, useTagDetailsBeforeTag } from '../../api/data-context/select';
import { CommitSchema } from '../../api/gitlab-types';
import { TagsDetail } from '../../api/rest-types';
import { ProjectFlavor } from '../../util/flavor-to-branch-name';
import { getCreatedAt, shouldFetchUnreleasedCommits } from '../../util/versions';
import './VersionDetails.scss';

const ticketIdRegex = () => /.*(WEN-\d*).*/;
const jirafyId = (jiraId: string) => `https://jira.cas.de/browse/${jiraId}`;

export interface VersionDetailProps {
  latestVersion: string;
}

const AccordionContent = ({ data }: { data: TagsDetail }) => {
  const createdAt = data.tag.startsWith('v') && getCreatedAt(data);
  const issuesList = (issues: CommitSchema[]) => {
    const shownIssues = issues.map((commit) => {
      const match = ticketIdRegex().exec(commit.title);
      return match && match[1] ? match[1] : null;
    }).filter(issueId => Boolean(issueId));
    const uniqueIssues = Array.from(new Set(shownIssues));
    if (!uniqueIssues.length) {
      return <div>Nothing to show here</div>;
    }
    return uniqueIssues.map((jiraId, index) => {
      const issueUrl = jirafyId(jiraId);
      return <li key={index}>
        <a href={issueUrl} target='_blank' rel="noreferrer">{issueUrl}</a>
      </li>;
    })
  };
  return <React.Fragment>
    <a className='uk-accordion-title' href='#' rel="noreferrer">
      {data.tag}
      {createdAt ? <span className='uk-text-small' style={{whiteSpace: 'pre'}}>   @{createdAt.toDateString()}</span> : <></>}
    </a>
    <div className='uk-accordion-content'>
      <ul>{
        issuesList(data.commits)
      }</ul>
    </div>
  </React.Fragment>;
};

export const VersionDetails = (props: VersionDetailProps) => {
  let { projectId, flavor } = useParams();
  let { dispatch } = useDataContext();
  let latestTagDetail = useLatestTagsDetail(projectId, flavor as ProjectFlavor);
  let latestCommits = useLatestCommits(projectId, flavor as ProjectFlavor);
  let displayedData = useTagDetailsBeforeTag(projectId, flavor as ProjectFlavor, latestTagDetail?.tag);
  useEffect(() => {
    if (!shouldFetchUnreleasedCommits(projectId, flavor as ProjectFlavor)) {
      return;
    }
    loadLatestCommits(dispatch, {
      projectId, flavor, latestVersion: latestTagDetail.tag
    });
  }, [projectId]);
  if (latestCommits) {
    displayedData = [
      {
        commits: latestCommits.commits,
        refs: [],
        tag: 'Unreleased'
      },
      ...displayedData,
    ]
  }
  return <React.Fragment>
    <div className='wdh-version-detail-container uk-container-small uk-align-center uk-margin-medium-top uk-box-shadow-small uk-padding-large'>
      <div className='uk-heading-small'>Changes</div>
      <ul uk-accordion='multiple: true'>
        {
          displayedData.map((tagData, index) => {
            return <li className='uk-open' key={index}>
              <AccordionContent data={tagData}></AccordionContent>
            </li>
          })
        }
      </ul>
    </div>
  </React.Fragment>;
};
