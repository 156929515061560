import * as React from 'react';
import { DataContextActions, DataContextDispatch, DataContextProviderProps, DataContextState } from './types';

const DataStateContext = React.createContext<
  { state: DataContextState; dispatch: DataContextDispatch } | undefined
>(undefined);

const dataContextReducer = (state: DataContextState, action: DataContextActions): DataContextState => {
  switch (action.type) {
    case 'finish load tag details': {
      const { projectId, data, latestTagData } = action;
      const newTagDatas: DataContextState['tagDatas'] = {
        ...state.tagDatas,
        [projectId]: data
      };
      const newLatestTagData: DataContextState['latestTags'] = {
        ...state.latestTags,
        [projectId]: latestTagData
      };
      return {
        ...state,
        tagDatas: newTagDatas,
        latestTags: newLatestTagData,
      };
    }
    case 'finish load latest commits': {
      const { projectId, data, flavor } = action;
      const newDatas: DataContextState['latestCommits'] = {
        ...state.latestCommits,
        [projectId]: {
          ...state.latestCommits[projectId],
          [flavor]: data
        }
      };
      return {
        ...state,
        latestCommits: newDatas
      };
    }
    default: {
      return state;
    }
  }
};

const DataProvider = ({ children }: DataContextProviderProps) => {
  const [state, dispatch] = React.useReducer<React.Reducer<DataContextState, DataContextActions>>(dataContextReducer, {
    tagDatas: {}, latestTags: {}, latestCommits: {},
  });
  const value = { state, dispatch };
  return (
    <DataStateContext.Provider value={value}>
      {children}
    </DataStateContext.Provider>
  )
};

const useDataContext = () => {
  const context = React.useContext(DataStateContext)
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider')
  }
  return context
}

export { DataProvider, useDataContext };

