import { BACKEND_CONFIG } from '../config';
import { GetCommitsBetweenResponse, GetCommitsParams, GetTagsDetailParams, GetTagsDetailResponse } from '../rest-types';

export class RestApi {

  static async getTagDetails(payload?: GetTagsDetailParams) {
    const params = new URLSearchParams(payload as any);
    const resposne = await fetch(`${BACKEND_CONFIG.host}/api/repo/taginfos?${params}`);
    const respJson = await resposne.json();
    return respJson as GetTagsDetailResponse;
  }

  static async getCommitsBetween(payload: GetCommitsParams) {
    const params = new URLSearchParams(payload as any);
    const resposne = await fetch(`${BACKEND_CONFIG.host}/api/repo/commitsBetween?${params}`);
    const respJson = await resposne.json();
    return respJson as GetCommitsBetweenResponse;

  }
}
