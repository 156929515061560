export const WEN_PROJECT_ID = '483';
export const BIOLAND_PROJECT_ID = '561';
export const COKA_PROJECT_ID = '554';

const PORT = window.location.hostname.includes('localhost') ? ':4000' : '';
export const BACKEND_CONFIG = {
  host: `${window.location.protocol}//${window.location.hostname}${PORT}`
};

export interface ProjectConfig {
  id: string;
  name: string;
}

const projects = new Map<string, ProjectConfig>();

projects.set(WEN_PROJECT_ID, {
  id: WEN_PROJECT_ID,
  name: 'We.Network'
});
projects.set(BIOLAND_PROJECT_ID, {
  id: BIOLAND_PROJECT_ID,
  name: 'Bioland'
});
projects.set(COKA_PROJECT_ID, {
  id: COKA_PROJECT_ID,
  name: 'City of KA'
});

export const projectIdToName = (projectId: string) => {
  return projects.get(projectId).name;
};
