import { GetTagsDetailResponse } from '../rest-types';

export class DataUtilImpl {

  getLatestDataForFlavor(data: GetTagsDetailResponse, matcher: (branchName: string) => boolean) {
    if (!data) {
      return null;
    }
    const latestData = data.find((tagData) => {
      const { refs } = tagData;
      const branchRefs = refs.filter(ref => ref.type === 'branch');
      return branchRefs.find(branchRef => {
        return matcher(branchRef.name);
      });
    });
    return latestData;
  }

}

export const DataUtil = new DataUtilImpl();
