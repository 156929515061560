import { BIOLAND_PROJECT_ID, COKA_PROJECT_ID, WEN_PROJECT_ID } from '../../api/config';
import './Dashboard.scss';
import { ProjectCardList } from './ProjectCardList';

const Dashboard = () => {
  return (
    <div>
      <div className='uk-flex uk-flex-column'>
        <ProjectCardList projectId={WEN_PROJECT_ID}></ProjectCardList>
        <div className='uk-divider-icon'></div>
        <ProjectCardList projectId={BIOLAND_PROJECT_ID}></ProjectCardList>
        <div className='uk-divider-icon'></div>
        <ProjectCardList projectId={COKA_PROJECT_ID}></ProjectCardList>
      </div>
    </div>
  );
}

export default Dashboard;
